import AllRecipesPage from "./AllRecipesPage";

export const AllRecipesPageConfig = {
  routes: [
    {
      path: "/pages/recipes",
      exact: true,
      component: AllRecipesPage,
    },
  ],
};
