import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
    apiKey: "AIzaSyCWx9pQuCd3xFBgB4ptACwsGPKz4aP6WM4",
    authDomain: "thewinehaven-27ee9.firebaseapp.com",
    projectId: "thewinehaven-27ee9",
    storageBucket: "thewinehaven-27ee9.appspot.com",
    messagingSenderId: "40176340428",
    appId: "1:40176340428:web:81edcbf2f32c330901a169"
};

// eslint-disable-next-line no-unused-vars
let app;
if (firebase.apps.length === 0) {
    app = firebase.initializeApp(firebaseConfig);
} else {
    app = firebase.app();
}

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
db.settings({ experimentalForceLongPolling: true, merge: true });


export { auth, db, storage, firebase };