import AddRecipePage from "./AddRecipePage";

export const AddRecipePageConfig = {
  routes: [
    {
      path: "/pages/posts/add-recipe",
      exact: true,
      component: AddRecipePage
    }
  ]
};
