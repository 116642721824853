import React from "react";
import OftadehLayout from "../../components/OftadehLayout/OftadehLayout";
import { db } from "../../firebase";

const OrderHistoryPage = (props) => {
  const [orders, setOrders] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [viewArray, setViewArray] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");

  React.useEffect(() => {
    db.collection("Orders")
      .where("completed", "==", true)
      .onSnapshot((snapshot) => {
        const data = [];

        snapshot.forEach((doc) => {
          data.push({ ...doc.data(), id: doc.id });
        });

        setOrders(data);
        setLoaded(true);
      });
  }, []);

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOrders = (searchQuery) => {
    return orders.filter(
      (order) =>
        order.id.includes(searchQuery) ||
        (order.username &&
          order.username.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  };

  return (
    <OftadehLayout>
      <h1>Order History</h1>
      <div>
        <input
          type="text"
          placeholder="Search by Order ID or Username"
          value={searchQuery}
          onChange={handleSearchQueryChange}
          style={{
            borderRadius: "7px",
            padding: "7px",
          }}
        />
      </div>
      {loaded &&
        filteredOrders(searchQuery).map((order, index) => (
          <div
            key={index}
            style={{
              border: "1px solid black",
              padding: "10px",
              margin: "10px",
              borderRadius: "2px",
            }}
          >
            <div>
              <p>Order ID: {order.id}</p>
              <p>UserName: {order.username}</p>
              <p>Phone Number: {order.phone}</p>
              <p>User Email: {order.userEmail}</p>
              <p>Total Order Amount: {order.total}$</p>
              <p>Address: {order.address}</p>
              <p>Delivery Charge: {order.DCharge}$</p>
              <div
                style={{
                  maxHeight: "50px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <button
                  style={{
                    marginLeft: "10px",
                    borderRadius: "7px",
                    padding: "7px",
                  }}
                  onClick={() => {
                    viewArray.includes(order.id)
                      ? setViewArray(viewArray.filter((id) => id !== order.id))
                      : setViewArray([...viewArray, order.id]);
                  }}
                >
                  {viewArray.includes(order.id) ? "Hide" : "View"}
                </button>
              </div>
              {viewArray.includes(order.id) && (
                <div>
                  <h3>Order items:</h3>
                  {order.cartItems.map((item) => (
                    <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                      <hr style={{ marginTop: "20px" }} />
                      <p>Name: {item.productName}</p>
                      <p>Brand: {item.productBrand}</p>
                      <p>Price: {item.productPrice}$</p>
                      <p>Quantity: {item.quantity}</p>{" "}
                      <hr style={{ marginBottom: "20px" }} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
    </OftadehLayout>
  );
};

export default OrderHistoryPage;
