import React from "react";
import OftadehLayout from "../../../components/OftadehLayout/OftadehLayout";
import {
  Typography,
  Grid,
  Button,
  makeStyles,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { db, firebase } from "../../../firebase";

const useStyles = makeStyles((theme) => ({
  my3: {
    margin: "1.3rem 0",
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: ".85rem",
  },
  p1: {
    padding: ".85rem",
  },
}));

const AllPostsPage = (props) => {
  const refInpt = React.useRef(null);
  const { history } = props;
  const classes = useStyles();
  const [orders, setOrders] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [editItem, setEditItem] = React.useState("");
  const [name, setName] = React.useState("");
  const [brand, setBrand] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [region, setRegion] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [servingTemp, setServingTemp] = React.useState("");
  const [pairings, setPairings] = React.useState([]);
  const [acidity, setAcidity] = React.useState("");
  const [aging, setAging] = React.useState("");
  const [vintage, setVintage] = React.useState("");
  const [sensesOnEye, setSensesOnEye] = React.useState("");
  const [sensesOnMouth, setSensesOnMouth] = React.useState("");
  const [sensesOnNose, setSensesOnNose] = React.useState("");
  const [vinification, setVinification] = React.useState("");
  const [sugar, setSugar] = React.useState("");
  const [grapeVariety, setGrapeVariety] = React.useState("");
  const [itemImage, setItemImage] = React.useState("");
  const [detailsImage, setDetailsImage] = React.useState("");
  // const [senseOnEye, setSenseOnEye] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [type, setType] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [isUploading1, setIsUploading1] = React.useState(false);
  const [isUploading2, setIsUploading2] = React.useState(false);
  const [isUploading3, setIsUploading3] = React.useState(false);
  const [detailsImageDesktop, setDetailsImageDesktop] = React.useState("");

  React.useEffect(() => {
    db.collection("items").onSnapshot((snapshot) => {
      const data = [];

      snapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });

      setOrders(data);
      setLoaded(true);

      console.log(data);
    });
  }, []);

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const types = ["All", "Red", "White", "Rose", "Sparkling", "Champagne"];

  const [filterType, setFilterType] = React.useState("All");

  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value);
  };

  const filteredOrders = (searchTerm) => {
    return orders.filter((order) => {
      const productNameMatches = order.productName
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const typeMatches =
        filterType === "All" || order.productType === filterType;

      return productNameMatches && typeMatches;
    });
  };

  const handleImageUpload1 = (e, setImage) => {
    setIsUploading1(true);
    const file = e.target.files[0];
    const storageRef = firebase.storage().ref(`images/${file.name}`);
    const task = storageRef.put(file);

    task.on(
      "state_changed",
      function progress(snapshot) {
        var percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(percentage);
      },
      function error(err) {
        console.log(err);
      },
      function complete() {
        task.snapshot.ref.getDownloadURL().then((url) => {
          setImage(url);
          setIsUploading1(false);
        });
      }
    );
  };

  const handleImageUpload2 = (e, setImage) => {
    setIsUploading2(true);
    const file = e.target.files[0];
    const storageRef = firebase.storage().ref(`images/${file.name}`);
    const task = storageRef.put(file);

    task.on(
      "state_changed",
      function progress(snapshot) {
        var percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(percentage);
      },
      function error(err) {
        console.log(err);
      },
      function complete() {
        task.snapshot.ref.getDownloadURL().then((url) => {
          setImage(url);
          setIsUploading2(false);
        });
      }
    );
  };

  const handleImageUpload3 = (e, setImage) => {
    setIsUploading3(true);
    const file = e.target.files[0];
    const storageRef = firebase.storage().ref(`images/${file.name}`);
    const task = storageRef.put(file);

    task.on(
      "state_changed",
      function progress(snapshot) {
        var percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(percentage);
      },
      function error(err) {
        console.log(err);
      },
      function complete() {
        task.snapshot.ref.getDownloadURL().then((url) => {
          setImage(url);
          setIsUploading3(false);
        });
      }
    );
  };

  const handleOpen = (order) => {
    setOpen(true);
    setEditItem(order.id);
    setName(order.productName);
    setBrand(order.productBrand);
    setType(order.productType);
    setDescription(order.productDescription);
    setPrice(order.productPrice);
    setRegion(order.productRegion);
    setCountry(order.productCountry);
    setServingTemp(order.servingTemp);
    setPairings(order.pairing);
    // const temp = order?.pairing?.split(",");
    // setPairings(temp);
    setAcidity(order.acidity);
    setAging(order.aging);
    setVintage(order.vintage);
    setSensesOnEye(order.senses.eye);
    setSensesOnMouth(order.senses.mouth);
    setSensesOnNose(order.senses.nose);
    setVinification(order.vinification);
    setSugar(order.sugar);
    setGrapeVariety(order.grapeVariety);
    setItemImage(order.productImage);
    setDetailsImage(order.detailImage);
    setDetailsImageDesktop(order.detailImageDesktop);
    console.log(order);
  };

  const handleClose = () => {
    setOpen(false);
    setEditItem("");
    setName("");
    setBrand("");
    setType("");
    setDescription("");
    setPrice("");
    setRegion("");
    setCountry("");
    setServingTemp("");
    setPairings("");
    setAcidity("");
    setAging("");
    setVintage("");
    setSensesOnEye("");
    setSensesOnMouth("");
    setSensesOnNose("");
    setVinification("");
    setSugar("");
    setGrapeVariety("");
    setItemImage("");
    setDetailsImage("");
    setDetailsImageDesktop("");
  };

  const handleSave = () => {
    db.collection("items")
      .doc(editItem)
      .update({
        productName: name,
        productBrand: brand,
        productType: type,
        productDescription: description,
        productPrice: price,
        productRegion: region,
        productCountry: country,
        servingTemp: servingTemp,
        pairing: pairings,
        acidity: acidity,
        aging: aging,
        vintage: vintage,
        senses: {
          eye: sensesOnEye,
          mouth: sensesOnMouth,
          nose: sensesOnNose,
        },
        vinification: vinification,
        sugar: sugar,
        grapeVariety: grapeVariety,
        productImage: itemImage,
        detailImage: detailsImage,
        detailImageDesktop: detailsImageDesktop,
      })
      .then(() => {
        console.log("Document successfully updated!");
        handleClose();
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };

  const handlePairingChange = (pairing, checked) => {
    if (checked) {
      setPairings([...pairings, pairing]);
    } else {
      setPairings(pairings.filter((p) => p !== pairing));
    }
  };

  const pairingOptions = [
    "Sea Food",
    "Veggies",
    "Cheese and Ham",
    "Poultry",
    "Red Meat",
    "Pasta and Pizza",
    "Desserts",
  ];

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight}>
          <Typography variant="h5" component="h1">
            Posts
          </Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={() => history.push("/pages/posts/add-post")}
            variant="outlined"
            color="primary"
            size="small"
          >
            Add Product
          </Button>
        </Grid>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchQueryChange}
          placeholder="Search by name or email"
          style={{
            borderRadius: "7px",
            padding: "7px",
            marginLeft: "10px",
          }}
        />
        <Grid
          item
          style={{
            marginLeft: "10px",
          }}
        >
          <FormControl>
            <InputLabel>Type</InputLabel>
            <Select value={filterType} onChange={handleFilterTypeChange}>
              {types.map((type) => (
                <MenuItem value={type} key={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <div className="InnerContainer">
            {loaded &&
              filteredOrders(searchQuery).map((order) => (
                <div
                  key={order.id}
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    margin: "10px",
                    borderRadius: "2px",
                  }}
                >
                  <img
                    src={order.productImage}
                    alt=""
                    width={100}
                    height={100}
                    style={{
                      objectFit: "contain",
                    }}
                  />
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {order.productName}
                  </p>
                  <p>{order.productDescription}</p>
                  <p>{order.productPrice}$</p>
                  <p>{order.Phone}</p>
                  <p>{order.productType}</p>
                  {/* {editItem === order.id && (
                    <div
                      style={{
                        justifyContent: "space-evenly",
                        margin: "10px",
                      }}
                    >
                      {" "}
                      <input
                        type="text"
                        placeholder="Description"
                        style={{
                          backgroundColor: "transparent",
                          color: "black",
                        }}
                        onBlur={(e) => {
                          setDescription(e.target.value);
                        }}
                      />
                      <input
                        type="number"
                        placeholder="Price"
                        style={{
                          backgroundColor: "transparent",
                          color: "black",
                          marginLeft: "10px",
                        }}
                        onBlur={(e) => {
                          setPrice(e.target.value);
                        }}
                      />
                      <input
                        type="text"
                        placeholder="Type"
                        style={{
                          backgroundColor: "transparent",
                          color: "black",
                          marginLeft: "10px",
                        }}
                        onBlur={(e) => {
                          setType(e.target.value);
                        }}
                      />
                      <input
                        type="file"
                        style={{
                          display: "none",
                        }}
                        ref={refInpt}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          const storageRef = firebase
                            .storage()
                            .ref(`images/${file.name}`);
                          const task = storageRef.put(file);

                          task.on(
                            "state_changed",
                            function progress(snapshot) {
                              var percentage =
                                (snapshot.bytesTransferred /
                                  snapshot.totalBytes) *
                                100;
                              console.log(percentage);
                            },
                            function error(err) {
                              console.log(err);
                            },
                            function complete() {
                              task.snapshot.ref.getDownloadURL().then((url) => {
                                db.collection("items").doc(order.id).update({
                                  productImage: url,
                                });
                              });
                            }
                          );
                        }}
                      />
                      <button
                        onClick={() => {
                          refInpt.current.click();
                        }}
                      >
                        <Icon>add_a_photo</Icon>
                      </button>
                    </div>
                  )} */}

                  <div>
                    <button
                      style={{
                        borderRadius: "7px",
                        padding: "7px",
                      }}
                      onClick={() => {
                        db.collection("items").doc(order.id).delete();
                      }}
                    >
                      <Icon>delete</Icon>
                    </button>
                    <button
                      style={{
                        marginLeft: "10px",
                        backgroundColor: order.BOTM ? "green" : "white",
                        borderRadius: "7px",
                        padding: "7px",
                      }}
                      onClick={() => {
                        const itemRef = db.collection("items").doc(order.id);
                        const botmRef = db.collection("Constants").doc("BOTM");

                        db.runTransaction((transaction) => {
                          return transaction.get(botmRef).then((doc) => {
                            const botmId = doc.data().id;

                            if (order.BOTM) {
                              transaction.update(itemRef, { BOTM: false });
                              transaction.update(botmRef, { id: "" });
                            } else {
                              if (botmId !== "") {
                                const prevBotmRef = db
                                  .collection("items")
                                  .doc(botmId);
                                transaction.update(prevBotmRef, {
                                  BOTM: false,
                                });
                              }
                              transaction.update(itemRef, {
                                BOTM: true,
                                offer: false,
                                bundle: false,
                              });
                              transaction.update(botmRef, { id: order.id });
                            }
                          });
                        })
                          .then(() => {
                            console.log("Transaction successfully committed!");
                          })
                          .catch((error) => {
                            console.error("Transaction failed: ", error);
                          });
                      }}
                    >
                      Bottle of the month
                    </button>

                    <button
                      style={{
                        marginLeft: "10px",
                        backgroundColor: order.offer ? "green" : "white",
                        borderRadius: "7px",
                        padding: "7px",
                      }}
                      onClick={() => {
                        if (order.offer === true) {
                          db.collection("items").doc(order.id).update({
                            offer: false,
                          });
                        } else {
                          db.collection("items").doc(order.id).update({
                            BOTM: false, // Set BOTM and bundle to false
                            offer: true,
                            bundle: false,
                          });
                        }
                      }}
                    >
                      Update Offer Status
                    </button>
                    <button
                      style={{
                        marginLeft: "10px",
                        backgroundColor: order.bundle ? "green" : "white",
                        borderRadius: "7px",
                        padding: "7px",
                      }}
                      onClick={() => {
                        if (order.bundle === true) {
                          db.collection("items").doc(order.id).update({
                            bundle: false,
                          });
                        } else {
                          db.collection("items").doc(order.id).update({
                            BOTM: false, // Set BOTM and offer to false
                            offer: false,
                            bundle: true,
                          });
                        }
                      }}
                    >
                      Update Bundle Status
                    </button>

                    <button
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "orange",
                        borderRadius: "7px",
                        padding: "7px",
                      }}
                      onClick={() => handleOpen(order)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Item</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Brand"
            fullWidth
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
          />
          <label htmlFor="category">Category</label>
          <select
            required
            onChange={(e) => setType(e.target.value)}
            value={type}
          >
            <option value="">Choose Category</option>
            <option value="Red">Red</option>
            <option value="White">White</option>
            <option value="Rose">Rose</option>
            <option value="Sparkling">Sparkling</option>
            <option value="Champagne">Champagne</option>

            <option value="Other">Other</option>
          </select>
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Price"
            fullWidth
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Region"
            fullWidth
            value={region}
            onChange={(e) => setRegion(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Country"
            fullWidth
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Serving Temperature"
            fullWidth
            value={servingTemp}
            onChange={(e) => setServingTemp(e.target.value)}
          />
          {/* <TextField
            margin="dense"
            label="Pairings"
            fullWidth
            value={pairings}
            onChange={(e) => setPairings(e.target.value)}
          /> */}
          <label htmlFor="">Pairings</label>
          <div>
            {pairingOptions.map((pairing) => (
              <label key={pairing}>
                <input
                  type="checkbox"
                  checked={pairings.includes(pairing)}
                  onChange={(e) =>
                    handlePairingChange(pairing, e.target.checked)
                  }
                />
                {pairing}
              </label>
            ))}
          </div>

          <TextField
            margin="dense"
            label="Acidity"
            fullWidth
            value={acidity}
            onChange={(e) => setAcidity(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Aging"
            fullWidth
            value={aging}
            onChange={(e) => setAging(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Vintage"
            fullWidth
            value={vintage}
            onChange={(e) => setVintage(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Senses on Eye"
            fullWidth
            value={sensesOnEye}
            onChange={(e) => setSensesOnEye(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Senses on Mouth"
            fullWidth
            value={sensesOnMouth}
            onChange={(e) => setSensesOnMouth(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Senses on Nose"
            fullWidth
            value={sensesOnNose}
            onChange={(e) => setSensesOnNose(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Vinification"
            fullWidth
            value={vinification}
            onChange={(e) => setVinification(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Sugar"
            fullWidth
            value={sugar}
            onChange={(e) => setSugar(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Grape Variety"
            fullWidth
            value={grapeVariety}
            onChange={(e) => setGrapeVariety(e.target.value)}
          />
          {/* <TextField
            margin="dense"
            label="Item Image"
            fullWidth
            value={itemImage}
            onChange={(e) => setItemImage(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Details Image"
            fullWidth
            value={detailsImage}
            onChange={(e) => setDetailsImage(e.target.value)}
          /> */}
          <div>
            <label htmlFor="itemImage">Item Image:</label>
            <br />
            {itemImage && (
              <img
                src={itemImage}
                alt="Item"
                width={200}
                height={200}
                style={{ objectFit: "contain" }}
              />
            )}
            <br />
            <input
              type="file"
              id="itemImage"
              onChange={(e) => handleImageUpload1(e, setItemImage)}
            />
          </div>
          <div>
            <label htmlFor="detailsImage">Details Image:</label>
            <br />
            {detailsImage && (
              <img
                src={detailsImage}
                alt="Details"
                width={200}
                height={200}
                style={{ objectFit: "contain" }}
              />
            )}
            <br />
            <input
              type="file"
              id="detailsImage"
              onChange={(e) => handleImageUpload2(e, setDetailsImage)}
            />
          </div>
          <div>
            <label htmlFor="detailsImage">Details Image Desktop:</label>
            <br />
            {detailsImageDesktop && (
              <img
                src={detailsImageDesktop}
                alt="Details"
                width={200}
                height={200}
                style={{ objectFit: "contain" }}
              />
            )}
            <br />
            <input
              type="file"
              id="detailsImage"
              onChange={(e) => handleImageUpload3(e, setDetailsImageDesktop)}
            />
          </div>
        </DialogContent>
        <Button
          onClick={() =>
            handleSave({
              id: editItem,
              productName: name,
              productType: type,
              productDescription: description,
              productPrice: price,
              productRegion: region,
              productCountry: country,
              productBrand: brand,
              servingTemp: servingTemp,
              pairing: pairings,
              acidity: acidity,
              aging: aging,
              vintage: vintage,
              senses: {
                eye: sensesOnEye,
                mouth: sensesOnMouth,
                nose: sensesOnNose,
              },
              vinification: vinification,
              sugar: sugar,
              grapeVariety: grapeVariety,
              productImage: itemImage,
              detailImage: detailsImage,
              detailImageDesktop: detailsImageDesktop,
            })
          }
          color="primary"
          disabled={isUploading1 || isUploading2 || isUploading3}
        >
          Save
        </Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </Dialog>
    </OftadehLayout>
  );
};

export default AllPostsPage;
