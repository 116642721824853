// import React from "react";
// import OftadehLayout from "../../../components/OftadehLayout/OftadehLayout";
// import { db, firebase } from "../../../firebase";
// import {
//   Typography,
//   Grid,
//   Button,
//   makeStyles,
//   Icon,
//   TextField,
//   TextareaAutosize,
//   CardMedia,
// } from "@material-ui/core";

// const useStyles = makeStyles((theme) => ({
//   my3: {
//     margin: "1.3rem 0",
//   },
//   mb0: {
//     marginBottom: 0,
//   },
//   mRight: {
//     marginRight: ".85rem",
//   },
//   p1: {
//     padding: ".85rem",
//   },
//   hiddenForm: {
//     display: "none",
//     marginTop: "1rem",
//   },
// }));

// const AllRecipesPage = (props) => {
//   const classes = useStyles();
//   const [recipes, setRecipes] = React.useState([]);
//   const [loaded, setLoaded] = React.useState(false);
//   const [editingRecipeId, setEditingRecipeId] = React.useState(null);
//   const [editIngredients, setEditIngredients] = React.useState("");
//   const [editPreparation, setEditPreparation] = React.useState("");
//   const [editSweetSavory, setEditSweetSavory] = React.useState("");
//   const [editWinePairing, setEditWinePairing] = React.useState("");
//   const [recipeImages, setRecipeImages] = React.useState({});
//   const [isUploading, setIsUploading] = React.useState(false);

//   React.useEffect(() => {
//     db.collection("recipes").onSnapshot((snapshot) => {
//       const data = [];

//       snapshot.forEach((doc) => {
//         data.push({ ...doc.data(), id: doc.id });
//       });

//       setRecipes(data);
//       setLoaded(true);
//     });
//   }, []);

//   const handleEditClick = async (recipeId) => {
//     const recipe = recipes.find((r) => r.id === recipeId);
//     if (recipe) {
//       setEditingRecipeId(recipeId);
//       setEditIngredients(recipe.ingredients.join("\n"));
//       setEditPreparation(recipe.prep.join("\n"));
//       setEditSweetSavory(recipe.sweetOrSavory.join("\n"));
//       setEditWinePairing(recipe.wineTypes.join("\n"));

//       // Fetch the recipe image
//       const storageRef = firebase.storage().ref(`recipes/${recipe.image}`);
//       try {
//         const url = await storageRef.getDownloadURL();
//         setRecipeImages((prevImages) => ({ ...prevImages, [recipeId]: url }));
//       } catch (error) {
//         console.error("Error fetching recipe image: ", error);
//       }
//     }
//   };

//   const handleCancelClick = () => {
//     setEditingRecipeId(null);
//     setEditIngredients("");
//     setEditPreparation("");
//     setEditSweetSavory("");
//     setEditWinePairing("");
//   };

//   const handleUpdateClick = () => {
//     if (editingRecipeId) {
//       const updatedRecipe = {
//         ingredients: editIngredients
//           .split("\n")
//           .map((ingredient) => ingredient.trim()),
//         prep: editPreparation.split("\n").map((step) => step.trim()),
//         sweetOrSavory: editSweetSavory.split("\n").map((sos) => sos.trim()),
//         wineTypes: editWinePairing.split("\n").map((wine) => wine.trim()),
//       };

//       db.collection("recipes")
//         .doc(editingRecipeId)
//         .update(updatedRecipe)
//         .then(() => {
//           handleCancelClick();
//         })
//         .catch((error) => {
//           console.error("Error updating recipe: ", error);
//         });
//     }
//   };

//   const handleImageUpload = (e, recipeId) => {
//     setIsUploading(true);
//     const file = e.target.files[0];
//     const storageRef = firebase.storage().ref(`recipes/${file.name}`);
//     const task = storageRef.put(file);

//     task.on(
//       "state_changed",
//       function progress(snapshot) {
//         var percentage =
//           (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//         console.log(percentage);
//       },
//       function error(err) {
//         console.log(err);
//       },
//       function complete() {
//         task.snapshot.ref.getDownloadURL().then((url) => {
//           setRecipeImages((prevImages) => ({ ...prevImages, [recipeId]: url }));
//           setIsUploading(false);
//         });
//       }
//     );
//   };

//   return (
//     <OftadehLayout>
//       <h1>All Recipes</h1>
//       {loaded &&
//         recipes.map((recipe) => (
//           <div
//             key={recipe.id}
//             style={{
//               border: "1px solid black",
//               padding: "10px",
//               margin: "10px",
//               borderRadius: "2px",
//             }}
//           >
//             <h3>Image</h3>
//             {editingRecipeId === recipe.id ? (
//               <div>
//                 <CardMedia
//                   component="img"
//                   alt="Recipe Image"
//                   height="140"
//                   image={recipeImages[recipe.image]}
//                   title="Recipe Image"
//                 />
//                 <input
//                   type="file"
//                   onChange={(e) => handleImageUpload(e, recipe.id)}
//                 />
//               </div>
//             ) : (
//               <CardMedia
//                 component="img"
//                 alt="Recipe Image"
//                 height="140"
//                 image={recipeImages[recipe.id]}
//                 title="Recipe Image"
//               />
//             )}
//             <h3>Ingredients</h3>
//             {editingRecipeId === recipe.id ? (
//               <TextareaAutosize
//                 value={editIngredients}
//                 onChange={(e) => setEditIngredients(e.target.value)}
//                 rows={4}
//                 className={classes.p1}
//               />
//             ) : (
//               <Typography variant="body1" className={classes.mb0}>
//                 {recipe.ingredients.map((ingredient, index) => (
//                   <li key={index}>{ingredient}</li>
//                 ))}
//               </Typography>
//             )}
//             <h3>Preparation</h3>
//             {editingRecipeId === recipe.id ? (
//               <TextareaAutosize
//                 value={editPreparation}
//                 onChange={(e) => setEditPreparation(e.target.value)}
//                 rows={4}
//                 className={classes.p1}
//               />
//             ) : (
//               <Typography variant="body1" className={classes.mb0}>
//                 {recipe.prep.map((step, index) => (
//                   <li key={index}>{step}</li>
//                 ))}
//               </Typography>
//             )}
//             <br />
//             <h3>Sweet/Savory</h3>
//             {editingRecipeId === recipe.id ? (
//               <TextareaAutosize
//                 value={editSweetSavory}
//                 onChange={(e) => setEditSweetSavory(e.target.value)}
//                 rows={4}
//                 className={classes.p1}
//               />
//             ) : (
//               <Typography variant="body1" className={classes.mb0}>
//                 {recipe.sweetOrSavory.map((sos, index) => (
//                   <li key={index}>{sos}</li>
//                 ))}
//               </Typography>
//             )}
//             <br />
//             <h3>Wine Pairing</h3>
//             {editingRecipeId === recipe.id ? (
//               <TextareaAutosize
//                 value={editWinePairing}
//                 onChange={(e) => setEditWinePairing(e.target.value)}
//                 rows={4}
//                 className={classes.p1}
//               />
//             ) : (
//               <Typography variant="body1" className={classes.mb0}>
//                 {recipe.wineTypes.map((wines, index) => (
//                   <li key={index}>{wines}</li>
//                 ))}
//               </Typography>
//             )}
//             {editingRecipeId === recipe.id ? (
//               <div>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={handleUpdateClick}
//                   disabled={isUploading}
//                 >
//                   Update
//                 </Button>
//                 <Button
//                   variant="contained"
//                   color="secondary"
//                   onClick={handleCancelClick}
//                 >
//                   Cancel
//                 </Button>
//               </div>
//             ) : (
//               <div>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={() => handleEditClick(recipe.id)}
//                 >
//                   Edit
//                 </Button>
//                 <Button
//                   variant="contained"
//                   color="secondary"
//                   onClick={() => {
//                     db.collection("recipes").doc(recipe.id).delete();
//                   }}
//                 >
//                   Delete
//                 </Button>
//               </div>
//             )}
//           </div>
//         ))}
//     </OftadehLayout>
//   );
// };

// export default AllRecipesPage;

import React from "react";
import OftadehLayout from "../../../components/OftadehLayout/OftadehLayout";
import { db, firebase } from "../../../firebase";
import {
  Typography,
  Grid,
  Button,
  makeStyles,
  Icon,
  TextField,
  TextareaAutosize,
  CardMedia,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  my3: {
    margin: "1.3rem 0",
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: ".85rem",
  },
  p1: {
    padding: ".85rem",
  },
  hiddenForm: {
    display: "none",
    marginTop: "1rem",
  },
}));

const AllRecipesPage = (props) => {
  const classes = useStyles();
  const [recipes, setRecipes] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [editingRecipeId, setEditingRecipeId] = React.useState(null);
  const [editIngredients, setEditIngredients] = React.useState("");
  const [editPreparation, setEditPreparation] = React.useState("");
  const [editSweetSavory, setEditSweetSavory] = React.useState([]);
  const [editWinePairing, setEditWinePairing] = React.useState([]);
  const [recipeImages, setRecipeImages] = React.useState({});
  const [isUploading, setIsUploading] = React.useState(false);

  React.useEffect(() => {
    db.collection("recipes").onSnapshot((snapshot) => {
      const data = [];

      snapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });

      setRecipes(data);
      setLoaded(true);
    });
  }, []);

  const handleEditClick = async (recipeId) => {
    const recipe = recipes.find((r) => r.id === recipeId);
    if (recipe) {
      setEditingRecipeId(recipeId);
      setEditIngredients(recipe.ingredients.join("\n"));
      setEditPreparation(recipe.prep.join("\n"));
      setEditSweetSavory(recipe.sweetOrSavory);
      setEditWinePairing(recipe.wineTypes);

      // Fetch the recipe image
      const storageRef = firebase.storage().ref(`recipes/${recipe.image}`);
      try {
        const url = await storageRef.getDownloadURL();
        setRecipeImages((prevImages) => ({ ...prevImages, [recipeId]: url }));
      } catch (error) {
        console.error("Error fetching recipe image: ", error);
      }
    }
  };

  const handleCancelClick = () => {
    setEditingRecipeId(null);
    setEditIngredients("");
    setEditPreparation("");
    setEditSweetSavory([]);
    setEditWinePairing([]);
  };

  const handleUpdateClick = () => {
    if (editingRecipeId) {
      const updatedRecipe = {
        ingredients: editIngredients
          .split("\n")
          .map((ingredient) => ingredient.trim()),
        prep: editPreparation.split("\n").map((step) => step.trim()),
        sweetOrSavory: editSweetSavory,
        wineTypes: editWinePairing,
      };

      db.collection("recipes")
        .doc(editingRecipeId)
        .update(updatedRecipe)
        .then(() => {
          handleCancelClick();
        })
        .catch((error) => {
          console.error("Error updating recipe: ", error);
        });
    }
  };

  const handleImageUpload = (e, recipeId) => {
    setIsUploading(true);
    const file = e.target.files[0];
    const storageRef = firebase.storage().ref(`recipes/${file.name}`);
    const task = storageRef.put(file);

    task.on(
      "state_changed",
      function progress(snapshot) {
        var percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(percentage);
      },
      function error(err) {
        console.log(err);
      },
      function complete() {
        task.snapshot.ref.getDownloadURL().then((url) => {
          setRecipeImages((prevImages) => ({ ...prevImages, [recipeId]: url }));
          setIsUploading(false);
        });
      }
    );
  };

  return (
    <OftadehLayout>
      <h1>All Recipes</h1>
      {loaded &&
        recipes.map((recipe) => (
          <div
            key={recipe.id}
            style={{
              border: "1px solid black",
              padding: "10px",
              margin: "10px",
              borderRadius: "2px",
            }}
          >
            <h3>Image</h3>
            {editingRecipeId === recipe.id ? (
              <div>
                <CardMedia
                  component="img"
                  alt="Recipe Image"
                  height="140"
                  image={recipeImages[recipe.image]}
                  title="Recipe Image"
                />
                <input
                  type="file"
                  onChange={(e) => handleImageUpload(e, recipe.id)}
                />
              </div>
            ) : (
              <CardMedia
                component="img"
                alt="Recipe Image"
                height="140"
                image={recipeImages[recipe.id]}
                title="Recipe Image"
              />
            )}
            <h3>Ingredients</h3>
            {editingRecipeId === recipe.id ? (
              <TextareaAutosize
                value={editIngredients}
                onChange={(e) => setEditIngredients(e.target.value)}
                rows={4}
                className={classes.p1}
              />
            ) : (
              <Typography variant="body1" className={classes.mb0}>
                {recipe.ingredients.map((ingredient, index) => (
                  <li key={index}>{ingredient}</li>
                ))}
              </Typography>
            )}
            <h3>Preparation</h3>
            {editingRecipeId === recipe.id ? (
              <TextareaAutosize
                value={editPreparation}
                onChange={(e) => setEditPreparation(e.target.value)}
                rows={4}
                className={classes.p1}
              />
            ) : (
              <Typography variant="body1" className={classes.mb0}>
                {recipe.prep.map((step, index) => (
                  <li key={index}>{step}</li>
                ))}
              </Typography>
            )}
            <br />
            <h3>Sweet/Savory</h3>
            {editingRecipeId === recipe.id ? (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editSweetSavory.includes("Sweet")}
                      onChange={() =>
                        setEditSweetSavory((prevSweetSavory) => {
                          if (prevSweetSavory.includes("Sweet")) {
                            return prevSweetSavory.filter(
                              (item) => item !== "Sweet"
                            );
                          } else {
                            return [...prevSweetSavory, "Sweet"];
                          }
                        })
                      }
                    />
                  }
                  label="Sweet"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editSweetSavory.includes("Savory")}
                      onChange={() =>
                        setEditSweetSavory((prevSweetSavory) => {
                          if (prevSweetSavory.includes("Savory")) {
                            return prevSweetSavory.filter(
                              (item) => item !== "Savory"
                            );
                          } else {
                            return [...prevSweetSavory, "Savory"];
                          }
                        })
                      }
                    />
                  }
                  label="Savory"
                />
              </>
            ) : (
              <Typography variant="body1" className={classes.mb0}>
                {recipe.sweetOrSavory.map((sos, index) => (
                  <li key={index}>{sos}</li>
                ))}
              </Typography>
            )}
            <br />
            <h3>Wine Pairing</h3>
            {editingRecipeId === recipe.id ? (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editWinePairing.includes("Red")}
                      onChange={() =>
                        setEditWinePairing((prevWinePairing) => {
                          if (prevWinePairing.includes("Red")) {
                            return prevWinePairing.filter(
                              (item) => item !== "Red"
                            );
                          } else {
                            return [...prevWinePairing, "Red"];
                          }
                        })
                      }
                    />
                  }
                  label="Red"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editWinePairing.includes("White")}
                      onChange={() =>
                        setEditWinePairing((prevWinePairing) => {
                          if (prevWinePairing.includes("White")) {
                            return prevWinePairing.filter(
                              (item) => item !== "White"
                            );
                          } else {
                            return [...prevWinePairing, "White"];
                          }
                        })
                      }
                    />
                  }
                  label="White"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editWinePairing.includes("Rose")}
                      onChange={() =>
                        setEditWinePairing((prevWinePairing) => {
                          if (prevWinePairing.includes("Rosé")) {
                            return prevWinePairing.filter(
                              (item) => item !== "Rose"
                            );
                          } else {
                            return [...prevWinePairing, "Rose"];
                          }
                        })
                      }
                    />
                  }
                  label="Rose"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editWinePairing.includes("Sparkling")}
                      onChange={() =>
                        setEditWinePairing((prevWinePairing) => {
                          if (prevWinePairing.includes("Sparkling")) {
                            return prevWinePairing.filter(
                              (item) => item !== "Sparkling"
                            );
                          } else {
                            return [...prevWinePairing, "Sparkling"];
                          }
                        })
                      }
                    />
                  }
                  label="Sparkling"
                />
              </>
            ) : (
              <Typography variant="body1" className={classes.mb0}>
                {recipe.wineTypes.map((wines, index) => (
                  <li key={index}>{wines}</li>
                ))}
              </Typography>
            )}
            {editingRecipeId === recipe.id ? (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateClick}
                  disabled={isUploading}
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleEditClick(recipe.id)}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    db.collection("recipes").doc(recipe.id).delete();
                  }}
                >
                  Delete
                </Button>
              </div>
            )}
          </div>
        ))}
    </OftadehLayout>
  );
};

export default AllRecipesPage;
