import CalendarPage from "./CalendarPage";

export const CalendarPageConfig = {
  routes: [
    {
      path: "/pages/users",
      exact: true,
      component: CalendarPage,
    },
  ],
};
