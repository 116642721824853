import SlideImagesPage from "./SlideImagesPage";

export const SlideImagesPageConfig = {
  routes: [
    {
      path: "/pages/slide-images",
      exact: true,
      component: SlideImagesPage,
    },
  ],
};
