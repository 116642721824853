import OrderHistoryPage from "./OrderHistoryPage";

export const OrderHistoryPageConfig = {
  routes: [
    {
      path: "/pages/order-history",
      exact: true,
      component: OrderHistoryPage,
    },
  ],
};
