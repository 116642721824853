import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import NavigationContext from "../../context/NavigationContext";
import NotificationsContainer from "../Notifications/Notifications-container";
import { Button } from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  TabWidth: {
    minWidth: 100,
    fontSize: 11,
    flex: 1,
  },
  backButton: {
    width: "100%",
    borderRadius: 0,
  },
}));

const OftadehRightPanelTab = () => {
  const classes = useStyles();

  const { setOpenRightPanel } = React.useContext(NavigationContext);

  return (
    <div className={classes.root}>
      <div>
        <Button
          variant="contained"
          classes={{ root: classes.backButton }}
          onClick={() => setOpenRightPanel(false)}
        >
          Back <ArrowRightAltIcon />
        </Button>
      </div>
      <NotificationsContainer />
    </div>
  );
};

export default OftadehRightPanelTab;
