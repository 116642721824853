import React, { useState } from "react";
// import "./AddProductPage.css";

import { db, storage } from "../../firebase";

// import { storage } from "../../../firebase";

const AddProductPage = (props) => {
  const storageRef = storage.ref();
  const [itemImageUrl, setItemImageUrl] = useState("");
  const [accessTokenItemImage, setAccessTokenItemImage] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [price, setPrice] = useState("");
  const [accessTokenDetailImage, setAccessTokenDetailImage] = useState("");

  const [test, setTest] = useState("");
  const [test2, setTest2] = useState("");

  const handlePickItemImage = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const fileRef = storageRef.child(`Delicacies/${file.name}`);
    const uploadTask = fileRef.put(file);

    uploadTask.on("state_changed", null, null, () => {
      fileRef.getDownloadURL().then((downloadUrl) => {
        setItemImageUrl(downloadUrl);
        fileRef.getMetadata().then((metadata) => {
          if (metadata && metadata.metadata) {
            setAccessTokenItemImage(
              metadata.metadata.firebaseStorageDownloadTokens
            );
          }
        });
      });
    });
  };

  const handlePickDetailImage = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const fileRef = storageRef.child(`detailDelicacies/${file.name}`);
    const uploadTask = fileRef.put(file);

    uploadTask.on("state_changed", null, null, () => {
      fileRef.getDownloadURL().then((downloadUrl) => {
        setTest(downloadUrl);
        fileRef.getMetadata().then((metadata) => {
          if (metadata && metadata.metadata) {
            setAccessTokenDetailImage(
              metadata.metadata.firebaseStorageDownloadTokens
            );
          }
        });
      });
    });
  };

  const handlePickDetailImage2 = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const fileRef = storageRef.child(`detailDelicacies/${file.name}`);
    const uploadTask = fileRef.put(file);

    uploadTask.on("state_changed", null, null, () => {
      fileRef.getDownloadURL().then((downloadUrl) => {
        setTest2(downloadUrl);
        fileRef.getMetadata().then((metadata) => {
          if (metadata && metadata.metadata) {
            setAccessTokenDetailImage(
              metadata.metadata.firebaseStorageDownloadTokens
            );
          }
        });
      });
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      const newItem = await db.collection("Products").add({
        Description: description,
        Image: itemImageUrl,
        Name: title,
        Price: price,
        Type: category,
        DetailImage: test,
        DetailImageDesktop: test2,
      });
      console.log("Item added with ID: ", newItem.id);
      setTitle("");
      setDescription("");
      setCategory("");
      setPrice("");
      props.history.push("/pages/posts/all-posts");
    } catch (error) {
      console.error("Error adding item: ", error);
    }
  }

  return (
    <div className="cont">
      <button
        className="back-button"
        onClick={() => props.history.push("/pages/posts/all-posts")}
      >
        Back
      </button>
      <form onSubmit={handleSubmit}>
        <h1>Add New Delicacies</h1>
        <label htmlFor="title">Name</label>
        <input
          required
          type="text"
          id="title"
          name="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <label htmlFor="description">Description</label>
        <input
          required
          type="text"
          id="description"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <label htmlFor="category">Category</label>
        <select
          required
          onChange={(e) => setCategory(e.target.value)}
          defaultValue="Category"
        >
          <option value="">Choose Category</option>
          <option value="Jars">Jars</option>
          <option value="Bottles">Bottles</option>
          <option value="Chocolates">Chocolates</option>
          <option value="GiftBoxes">Gift Boxes</option>
        </select>
        <label htmlFor="price">Price</label>
        <input
          required
          type="number"
          id="price"
          name="price"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />

        <label htmlFor="image-upload">
          Upload Item Image {"(1427 * 3890)"}
        </label>
        <input
          required
          type="file"
          id="image-upload"
          onChange={handlePickItemImage}
          accept="image/*"
        />
        <label htmlFor="image-upload2">
          Upload Details Image {"(1100 * 2166)"}
        </label>
        <input
          required
          type="file"
          id="image-upload2"
          onChange={handlePickDetailImage}
          accept="image/*"
        />
        <label htmlFor="image-upload2">
          Upload Details Image Desktop {"(1100 * 2166)"}
        </label>
        <input
          required
          type="file"
          id="image-upload2"
          onChange={handlePickDetailImage2}
          accept="image/*"
        />
        {test !== "" && itemImageUrl !== "" ? (
          <button type="submit">Add Product</button>
        ) : (
          <button type="submit" style={{ opacity: "0.5" }} disabled>
            Add Product
          </button>
        )}
      </form>
    </div>
  );
};

export default AddProductPage;
