import React, { useState, useEffect } from "react";
import OftadehLayout from "../../components/OftadehLayout/OftadehLayout";
import { db } from "../../firebase";

const ConstantsPage = () => {
  const [newCashback, setNewCashback] = useState("");
  const [cashback, setCashback] = useState(0);
  const [delivery, setDelivery] = useState(0);
  const [banner, setBanner] = useState("");

  const handleCashbackChange = (event) => {
    db.collection("Constants")
      .doc("Loyalty")
      .update({
        Perc: cashback,
      })
      .then(() => {
        alert("Cashback upodated successfully!");
      });
  };

  const handleDeliveryChange = (event) => {
    db.collection("Constants")
      .doc("Delivery")
      .update({
        Charge: delivery,
      })
      .then(() => {
        alert("Delivery charge updated successfully!");
      });
  };

  const handleBannerChange = (event) => {
    if (banner.length > 0 && banner.length <= 48) {
      db.collection("Constants")
        .doc("Banner")
        .update({
          Text: banner,
        })
        .then(() => {
          alert("Banner updated successfully!");
        });
    } else {
      alert("Banner must be between 1 and 47 characters long!");
    }
  };

  useEffect(() => {
    db.collection("Constants")
      .doc("Loyalty")
      .get()
      .then((doc) => {
        setCashback(doc.data().Perc);
      });
    db.collection("Constants")
      .doc("Delivery")
      .get()
      .then((doc) => {
        setDelivery(doc.data().Charge);
      });
    db.collection("Constants")
      .doc("Banner")
      .get()
      .then((doc) => {
        setBanner(doc.data().Text);
      });
  }, []);

  return (
    <OftadehLayout>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <h5 className="card-header">Loyalty Cashback</h5>
              <div className="card-body">
                <h5 className="card-title">Current Cashback: {cashback}%</h5>
                <p className="card-text">Change Cashback</p>
                <input
                  type="number"
                  className="form-control"
                  id="cashback"
                  value={cashback}
                  onChange={(e) => setCashback(e.target.value)}
                />
                <button
                  className="btn btn-primary mt-2"
                  onClick={handleCashbackChange}
                >
                  Change Cashback
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <h5 className="card-header">Delivery Charge</h5>
              <div className="card-body">
                <h5 className="card-title">Current Charge: {delivery}</h5>
                <p className="card-text">Change Charge</p>
                <input
                  type="number"
                  className="form-control"
                  id="delivery"
                  value={delivery}
                  onChange={(e) => setDelivery(e.target.value)}
                />
                <button
                  className="btn btn-primary mt-2"
                  onClick={handleDeliveryChange}
                >
                  Change Charge
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <div className="card">
              <h5 className="card-header">Banner</h5>
              <div className="card-body">
                <h5 className="card-title">Current Banner: {banner}</h5>
                <p className="card-text">Change Banner</p>
                <textarea
                  type="text"
                  className="form-control"
                  id="banner"
                  value={banner}
                  onChange={(e) => setBanner(e.target.value)}
                  rows="3"
                  cols="50"
                  maxLength={48}
                  minLength={1}
                />
                <button
                  className="btn btn-primary mt-2"
                  onClick={handleBannerChange}
                >
                  Change Banner
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OftadehLayout>
  );
};

export default ConstantsPage;
