import React, { useState } from "react";
import "./AddRecipe.css";

import { db, storage } from "../../../firebase";

const AddRecipePage = (props) => {
  const storageRef = storage.ref();
  const [prep, setPrep] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [itemImageUrl, setItemImageUrl] = useState("");
  const [name, setName] = useState("");
  const [accessTokenItemImage, setAccessTokenItemImage] = useState("");
  const [wineTypes, setWineTypes] = useState([]);
  const [sweetOrSavory, setSweetOrSavory] = useState([]);

  console.log("wineTypes", wineTypes);
  console.log("sweetOrSavory", sweetOrSavory);

  const [loading, setLoading] = useState(false);

  const handlePickItemImage = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const fileRef = storageRef.child(`recipes/${file.name}`);
    const uploadTask = fileRef.put(file);

    uploadTask.on("state_changed", null, null, () => {
      fileRef.getDownloadURL().then((downloadUrl) => {
        setItemImageUrl(downloadUrl);
        fileRef.getMetadata().then((metadata) => {
          if (metadata && metadata.metadata) {
            setAccessTokenItemImage(
              metadata.metadata.firebaseStorageDownloadTokens
            );
          }
        });
      });
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    // Split the ingredients and prep strings into arrays, they should be splitted by the comma
    const ingredientsArray = ingredients
      .join(",")
      .split(/\r?\n/)
      .map((ingredient) => ingredient.trim());

    const prepArray = prep
      .join(",")
      .split(/\r?\n/)
      .map((prep) => prep.trim());

    try {
      const newItem = await db.collection("recipes").add({
        prep: prepArray,
        ingredients: ingredientsArray,
        image: itemImageUrl,
        wineTypes: wineTypes,
        sweetOrSavory: sweetOrSavory,
        name: name,
      });
      console.log("Recipe added with ID: ", newItem.id);

      // Reset the state variables
      setIngredients([]);
      setPrep([]);
      setWineTypes([]);
      setSweetOrSavory([]);
      setName("");

      setLoading(false);
      props.history.push("/pages/posts/all-recipes");
    } catch (error) {
      console.error("Error adding recipe: ", error);
    }
  }

  return (
    <div className="cont">
      <button
        className="back-button"
        onClick={() => props.history.push("/pages/posts/all-recipes")}
      >
        Back
      </button>
      <form onSubmit={handleSubmit}>
        <h1>Add New Recipe</h1>

        <label htmlFor="name">Name</label>
        <input
          required
          type="text"
          id="name"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <label htmlFor="prep">Preparations</label>
        <textarea
          required
          id="prep"
          name="prep"
          value={prep}
          onChange={(e) => setPrep(e.target.value.split(","))}
        />
        <label htmlFor="ingredients">Ingredients</label>
        <textarea
          required
          id="ingredients"
          name="ingredients"
          value={ingredients}
          onChange={(e) => setIngredients(e.target.value.split(","))}
        />
        <label htmlFor="wine-types">Wine Types</label>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <input
              type="checkbox"
              id="red-wine"
              name="wineTypes"
              value="Red"
              onChange={(e) => {
                if (e.target.checked) {
                  setWineTypes([...wineTypes, e.target.value]);
                } else {
                  setWineTypes(
                    wineTypes.filter((type) => type !== e.target.value)
                  );
                }
              }}
            />
            <label htmlFor="red-wine">Red</label>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <input
              type="checkbox"
              id="white-wine"
              name="wineTypes"
              value="White"
              onChange={(e) => {
                if (e.target.checked) {
                  setWineTypes([...wineTypes, e.target.value]);
                } else {
                  setWineTypes(
                    wineTypes.filter((type) => type !== e.target.value)
                  );
                }
              }}
              style={{ marginLeft: "20px" }}
            />
            <label htmlFor="white-wine">White</label>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <input
              type="checkbox"
              id="rose-wine"
              name="wineTypes"
              value="Rose"
              onChange={(e) => {
                if (e.target.checked) {
                  setWineTypes([...wineTypes, e.target.value]);
                } else {
                  setWineTypes(
                    wineTypes.filter((type) => type !== e.target.value)
                  );
                }
              }}
              style={{ marginLeft: "20px" }}
            />
            <label htmlFor="rose-wine">Rose</label>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <input
              type="checkbox"
              id="sparkling-wine"
              name="wineTypes"
              value="Sparkling"
              onChange={(e) => {
                if (e.target.checked) {
                  setWineTypes([...wineTypes, e.target.value]);
                }
              }}
              style={{ marginLeft: "20px" }}
            />
            <label htmlFor="sparkling-wine">Sparkling</label>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <input
              type="checkbox"
              id="champagne"
              name="wineTypes"
              value="Champagne"
              onChange={(e) => {
                if (e.target.checked) {
                  setWineTypes([...wineTypes, e.target.value]);
                }
              }}
              style={{ marginLeft: "20px" }}
            />
            <label htmlFor="sparkling-wine">Champagne</label>
          </div>
        </div>
        <label htmlFor="sweet-or-savory">Sweet or Savory</label>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <input
              type="checkbox"
              id="sweet"
              name="sweetOrSavory"
              value="Sweet"
              onChange={(e) => {
                if (e.target.checked) {
                  setSweetOrSavory([...sweetOrSavory, e.target.value]);
                } else {
                  setSweetOrSavory(
                    sweetOrSavory.filter((type) => type !== e.target.value)
                  );
                }
              }}
              style={{ marginLeft: "20px" }}
            />
            <label htmlFor="sweet">Sweet</label>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <input
              type="checkbox"
              id="savory"
              name="sweetOrSavory"
              value="Savory"
              onChange={(e) => {
                if (e.target.checked) {
                  setSweetOrSavory([...sweetOrSavory, e.target.value]);
                }
              }}
              style={{ marginLeft: "20px" }}
            />
            <label htmlFor="savory">Savory</label>
          </div>
        </div>
        <label htmlFor="image-upload2">
          Upload Recipe Image {"(Ratio 3/4)"}
        </label>
        <input
          required
          type="file"
          id="image-upload2"
          onChange={handlePickItemImage}
          accept="image/*"
        />
        {itemImageUrl == "" ? (
          <p>just a second</p>
        ) : (
          <button type="submit">Add Recipe</button>
        )}
      </form>
    </div>
  );
};

export default AddRecipePage;
