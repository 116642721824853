import AllProductsPage from "./AllProductsPage";

export const AllProductPageConfig = {
  routes: [
    {
      path: "/pages/all-delicacies",
      exact: true,
      component: AllProductsPage,
    },
  ],
};
