// create a page that will display all the slide images in the database and allow the user to add, edit, and delete them. can add up to 5 images

import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  makeStyles,
  Icon,
  TextField,
  TextareaAutosize,
  CardMedia,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
import OftadehLayout from "../../components/OftadehLayout/OftadehLayout";
import { Link } from "react-router-dom";
import { storage } from "../../firebase";
import { db } from "../../firebase";

const useStyles = makeStyles((theme) => ({
  my3: {
    margin: "1.3rem 0",
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: ".85rem",
  },
  p1: {
    padding: ".85rem",
  },
  hiddenForm: {
    display: "none",
    marginTop: "1rem",
  },
}));

const SlideImagesPage = (props) => {
  const classes = useStyles();
  const [slideImages, setSlideImages] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [editingSlideImageId, setEditingSlideImageId] = useState(null);
  const [editSlideImage, setEditSlideImage] = useState("");
  const [slideImageImages, setSlideImageImages] = useState({});
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    db.collection("SlideImages").onSnapshot((snapshot) => {
      const data = [];

      snapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });

      setSlideImages(data);
      setLoaded(true);
    });
  }, []);

  const handleEditClick = async (slideImageId) => {
    const slideImage = slideImages.find((r) => r.id === slideImageId);

    setEditingSlideImageId(slideImageId);
    setEditSlideImage(slideImage.slideImage);
  };

  const handleDeleteClick = async (slideImageId) => {
    try {
      await db.collection("SlideImages").doc(slideImageId).delete();
      alert("Slide Image Deleted Successfully");
    } catch (error) {
      console.log(error);
      alert("Failed to Delete Slide Image");
    }
  };

  const handleSaveClick = async () => {
    try {
      if (slideImageImages) {
        setIsUploading(true);
        const uploadTask = storage
          .ref(`SlideImages/${slideImageImages.name}`)
          .put(slideImageImages);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (error) => {
            console.log(error);
          },
          () => {
            storage
              .ref("SlideImages")
              .child(slideImageImages.name)
              .getDownloadURL()
              .then((url) => {
                db.collection("SlideImages").doc(editingSlideImageId).update({
                  slideImage: url,
                });
                alert("Slide Image Updated Successfully");
                setEditingSlideImageId(null);
                setIsUploading(false);
                setSlideImageImages({});
              });
          }
        );
      } else {
        await db.collection("SlideImages").doc(editingSlideImageId).update({
          slideImage: editSlideImage,
        });
        alert("Slide Image Updated Successfully");
        setEditingSlideImageId(null);
        // window.location.reload();
      }
    } catch (error) {
      console.log(error);
      alert("Failed to Update Slide Image");
    }
  };

  const handleAddClick = async () => {
    try {
      await db.collection("SlideImages").add({
        slideImage: editSlideImage,
      });
      alert("Slide Image Added Successfully");
      setEditingSlideImageId(null);
    } catch (error) {
      console.log(error);
      alert("Failed to Add Slide Image");
    }
  };

  const handleSlideImageChange = (e) => {
    if (e.target.files[0]) {
      setSlideImageImages(e.target.files[0]);
    }
  };

  const handleSlideImageUpload = () => {
    setIsUploading(true);
    const uploadTask = storage
      .ref(`SlideImages/${slideImageImages.name}`)
      .put(slideImageImages);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref("SlideImages")
          .child(slideImageImages.name)
          .getDownloadURL()
          .then((url) => {
            db.collection("SlideImages").add({
              slideImage: url,
            });
            alert("Slide Image Added Successfully");
            setEditingSlideImageId(null);
            setIsUploading(false);
          });
      }
    );
  };

  const handleImageEditClick = async (slideImageId) => {
    setEditingSlideImageId(slideImageId);
    setSlideImageImages({});
  };

  return (
    <OftadehLayout>
      <Typography variant="h4" className={classes.mb0}>
        Slide Images {"(612 * 344)"}
      </Typography>
      <Typography variant="subtitle2">
        Slide Images Page is where you can add, edit, and delete slide images
      </Typography>
      <Grid container spacing={3} className={classes.my3}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Slide Image"
            variant="outlined"
            fullWidth
            value={editSlideImage}
            onChange={(e) => setEditSlideImage(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={editingSlideImageId ? handleSaveClick : handleAddClick}
          >
            {editingSlideImageId ? "Save" : "Add"}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.my3}>
        <Grid item xs={12} sm={6}>
          <input
            accept="image/*"
            className={classes.hiddenForm}
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleSlideImageChange}
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span">
              Add Slide Image
            </Button>
          </label>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSlideImageUpload}
            disabled={isUploading}
          >
            {isUploading ? "Uploading..." : "Upload"}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.my3}>
        {loaded ? (
          slideImages.map((slideImage) => (
            <Grid item xs={12} sm={6} md={4} key={slideImage.id}>
              <Card>
                <CardMedia
                  component="img"
                  alt={slideImage.slideImage}
                  height="140"
                  image={slideImage.slideImage}
                  title={slideImage.slideImage}
                />
                <CardContent>
                  {editingSlideImageId === slideImage.id ? (
                    <div>
                      <input
                        accept="image/*"
                        className={classes.hiddenForm}
                        id={`slide-image-file-${slideImage.id}`}
                        type="file"
                        onChange={handleSlideImageChange}
                      />
                      <label htmlFor={`slide-image-file-${slideImage.id}`}>
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Select Image
                        </Button>
                      </label>
                    </div>
                  ) : (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {slideImage.slideImage}
                    </Typography>
                  )}
                </CardContent>

                <CardActions>
                  {editingSlideImageId === slideImage.id ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSaveClick}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleEditClick(slideImage.id)}
                    >
                      Edit
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDeleteClick(slideImage.id)}
                  >
                    Delete
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary" component="p">
              Loading...
            </Typography>
          </Grid>
        )}
      </Grid>
    </OftadehLayout>
  );
};

export default SlideImagesPage;
