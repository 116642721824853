import React, { useState, useEffect } from "react";
import OftadehLayout from "../../components/OftadehLayout/OftadehLayout";
import { db } from "../../firebase";

const CalendarPage = () => {
  const [orders, setOrders] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [newCashback, setNewCashback] = useState("");
  const [cashback, setCashback] = useState(0);
  const [delivery, setDelivery] = useState(0);

  const handleCashbackChange = (event) => {
    db.collection("Constants")
      .doc("Loyalty")
      .update({
        Perc: cashback,
      })
      .then(() => {
        alert("Document successfully written!");
      });
  };

  const handleDeliveryChange = (event) => {
    db.collection("Constants")
      .doc("Delivery")
      .update({
        Charge: delivery,
      })
      .then(() => {
        alert("Document successfully written!");
      });
  };

  useEffect(() => {
    const unsubscribe = db.collection("Users").onSnapshot((snapshot) => {
      const data = [];
      snapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      setOrders(data);
      setLoaded(true);
    });
    db.collection("Constants")
      .doc("Loyalty")
      .get()
      .then((doc) => {
        setCashback(doc.data().Perc);
      });
    db.collection("Constants")
      .doc("Delivery")
      .get()
      .then((doc) => {
        setDelivery(doc.data().Charge);
      });

    return () => unsubscribe();
  }, []);

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredUsers = (searchTerm) => {
    return orders.filter(
      (user) =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const handleUpdateCashback = (userId, deduction) => {
    const userRef = db.collection("Users").doc(userId);
    userRef.update({ cashback: db.FieldValue.increment(-deduction) });
  };

  const handleNewCashbackChange = (event) => {
    setNewCashback(event.target.value);
  };

  const handleSubmit = (event, userId, currentCashback) => {
    event.preventDefault();
    if (newCashback < currentCashback) {
      const deduction = currentCashback - newCashback;
      handleUpdateCashback(userId, deduction);
      setNewCashback("");
    } else {
      alert("Deduction should be less than the current cashback");
    }
  };

  return (
    <OftadehLayout>
      <h1>Users</h1>
      <div
        style={{
          display: "flex",
        }}
      >
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchQueryChange}
          placeholder="Search by name or email"
          style={{
            borderRadius: "7px",
            padding: "7px",
          }}
        />
        <div
          style={{
            display: "flex",
          }}
        >
          <p>Cashback Percentage:</p>
          <input
            type="text"
            value={cashback}
            onChange={(e) => setCashback(e.target.value)}
            placeholder="Enter Chashback Percentage"
            style={{
              borderRadius: "7px",
              padding: "7px",
            }}
          />
          <button onClick={() => handleCashbackChange()}>Update</button>
        </div>

        <div
          style={{
            display: "flex",
          }}
        >
          <p>Delivery Charge:</p>
          <input
            type="text"
            value={delivery}
            onChange={(e) => setDelivery(e.target.value)}
            placeholder="Enter Delivery Charge"
            style={{
              borderRadius: "7px",
              padding: "7px",
            }}
          />
          <button onClick={() => handleDeliveryChange()}>Update</button>
        </div>
      </div>

      {loaded &&
        filteredUsers(searchQuery).map((user, index) => (
          <div
            key={index}
            style={{
              border: "1px solid black",
              padding: "10px",
              margin: "10px",
              borderRadius: "2px",
            }}
          >
            <div>
              <p>
                {index + 1} - User ID: {user.id}
              </p>
              <p>Full Name: {user.name + " " + user.lastname}</p>
              <p>Email: {user.email}</p>
              <p>Username: {user.username}</p>
              <p>Phone Number: {user.phone}</p>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p>Loyalty Points: {user.cashback}</p>
                <form
                  onSubmit={(event) =>
                    handleSubmit(event, user.id, user.cashback)
                  }
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <label>
                    Loyalty Points:
                    <input
                      type="number"
                      value={newCashback}
                      onChange={handleNewCashbackChange}
                    />
                  </label>
                  <button type="submit">Update</button>
                </form>
              </div>
            </div>
          </div>
        ))}
    </OftadehLayout>
  );
};

export default CalendarPage;
