import React, { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import routes from "../../oftadeh-configs/routesConfig";
import "./OftadehRoutes.css";
import img from "../../assets/smallcircle1.jpg";

const LoginPage = ({ authenticateUser }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    if (email === "info@thewinehaven.shop" && password === "admin123") {
      authenticateUser();
    } else {
      alert("Invalid email or password");
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={img} alt="" width={200} height={200} />
      <form onSubmit={handleSubmit}>
        <label>
          Email:
          <input
            required
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>
        <label>
          Password:
          <input
            required
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

const OftadehRoutes = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const authenticateUser = () => {
    setAuthenticated(true);
  };
  return (
    <>
      {authenticated ? (
        <Switch>
          {routes.map((route, index) => (
            <Route key={index} {...route} />
          ))}
        </Switch>
      ) : (
        <LoginPage authenticateUser={authenticateUser} />
      )}
    </>
  );
};

export default OftadehRoutes;
