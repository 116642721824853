import ConstantsPage from "./ConstantsPage";

export const ConstantsPageConfig = {
  routes: [
    {
      path: "/pages/constants",
      exact: true,
      component: ConstantsPage,
    },
  ],
};
