import React from "react";
import { Redirect } from "react-router-dom";

import { DashboardPageConfig } from "../pages/dashboard/DashboardPageConfig";
import { AboutPageConfig } from "../pages/about/AboutPageConfig";
import { AddPostPageConfig } from "../pages/posts/add-post/AddPostPageConfig";
import { AllPostsPageConfig } from "../pages/posts/all-posts/AllPostsPageConfig";
import { AllRecipesPageConfig } from "../pages/posts/all-recipes/AllRecipesPageConfig";
import { CalendarPageConfig } from "../pages/calendar/CalendarPageConfig";
import { AddRecipePageConfig } from "../pages/posts/add-recipe/AddRecipePageConfig";
import { OrderHistoryPageConfig } from "../pages/orderHistory/OrderHistoryPageConfig";
import { ConstantsPageConfig } from "../pages/constants/ConstantsPageConfig";
import { SlideImagesPageConfig } from "../pages/slideImages/SlideImagesPageConfig";
import { AddProductPageConfig } from "../pages/add-product/AddProductPageConfig";
import { AllProductPageConfig } from "../pages/all-poducts/AllProductsPageConfig";

const routeConfigs = [
  ...DashboardPageConfig.routes,
  ...AllPostsPageConfig.routes,
  ...AllRecipesPageConfig.routes,
  ...AddPostPageConfig.routes,
  ...AddRecipePageConfig.routes,
  ...CalendarPageConfig.routes,
  ...AboutPageConfig.routes,
  ...OrderHistoryPageConfig.routes,
  ...ConstantsPageConfig.routes,
  ...SlideImagesPageConfig.routes,
  ...AddProductPageConfig.routes,
  ...AllProductPageConfig.routes,
];

const routes = [
  ...routeConfigs,
  {
    component: () => <Redirect to="/" />,
  },
];

export default routes;
