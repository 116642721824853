import AddProductPage from "./AddProductPage";

export const AddProductPageConfig = {
  routes: [
    {
      path: "/pages/add-product",
      exact: true,
      component: AddProductPage,
    },
  ],
};
