import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import { db } from "../../firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const NotificationsContainer = (props) => {
  const [loaded, setLoaded] = React.useState(false);
  const [data, setData] = React.useState([]);

  const classes = useStyles();
  React.useEffect(() => {
    db.collection("ContactUs").onSnapshot((snapshot) => {
      const data = [];

      snapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });

      setData(data);
      setLoaded(true);
    });
  }, []);

  return (
    <List className={classes.root}>
      {loaded &&
        data.map((order) => (
          <div
            style={{
              border: "1px solid black",
              padding: "10px",
              margin: "10px",
              borderRadius: "2px",
            }}
          >
            <p>
              Name: {order.Firstname} {order.Lastname}
            </p>
            <p>Message: {order.Message}</p>
            <p>Email: {order.email}</p>
            <p>Phone: {order.Phone}</p>

            <button
              onClick={() => {
                db.collection("ContactUs").doc(order.id).delete();
              }}
            >
              Delete
            </button>
          </div>
        ))}
    </List>
  );
};

export default NotificationsContainer;
