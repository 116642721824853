import React, { useState } from "react";
import "./AddPost.css";

import { db, storage } from "../../../firebase";

// import { storage } from "../../../firebase";

const AddPostPage = (props) => {
  const storageRef = storage.ref();
  const [itemImageUrl, setItemImageUrl] = useState("");
  const [accessTokenItemImage, setAccessTokenItemImage] = useState("");
  const [title, setTitle] = useState("");
  const [brand, setBrand] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [price, setPrice] = useState("");
  const [region, setRegion] = useState("");
  const [country, setCountry] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [acidity, setAcidity] = useState("");
  const [aging, setAging] = useState("");
  const [grapeVariety, setGrapeVariety] = useState("");

  const [accessTokenDetailImage, setAccessTokenDetailImage] = useState("");
  const [accessTokenDetailImage2, setAccessTokenDetailImage2] = useState("");

  const [eye, setEye] = useState("");
  const [nose, setNose] = useState("");
  const [mouth, setMouth] = useState("");
  const [servingTemp, setServingTemp] = useState("");
  const [pairings, setPairings] = useState([]);
  const [vinification, setVinification] = useState("");
  const [sugar, setSugar] = useState("");
  const [vintage, setVintage] = useState("");

  const [test, setTest] = useState("");
  const [test2, setTest2] = useState("");

  const handlePickItemImage = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const fileRef = storageRef.child(`Images/${file.name}`);
    const uploadTask = fileRef.put(file);

    uploadTask.on("state_changed", null, null, () => {
      fileRef.getDownloadURL().then((downloadUrl) => {
        setItemImageUrl(downloadUrl);
        fileRef.getMetadata().then((metadata) => {
          if (metadata && metadata.metadata) {
            setAccessTokenItemImage(
              metadata.metadata.firebaseStorageDownloadTokens
            );
          }
        });
      });
    });
  };

  const handlePickDetailImage = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const fileRef = storageRef.child(`detailImages/${file.name}`);
    const uploadTask = fileRef.put(file);

    uploadTask.on("state_changed", null, null, () => {
      fileRef.getDownloadURL().then((downloadUrl) => {
        setTest(downloadUrl);
        fileRef.getMetadata().then((metadata) => {
          if (metadata && metadata.metadata) {
            setAccessTokenDetailImage(
              metadata.metadata.firebaseStorageDownloadTokens
            );
          }
        });
      });
    });
  };

  const handlePickDetailImageDesktop = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const fileRef = storageRef.child(`detailImages/${file.name}`);
    const uploadTask = fileRef.put(file);

    uploadTask.on("state_changed", null, null, () => {
      fileRef.getDownloadURL().then((downloadUrl) => {
        setTest2(downloadUrl);
        fileRef.getMetadata().then((metadata) => {
          if (metadata && metadata.metadata) {
            setAccessTokenDetailImage2(
              metadata.metadata.firebaseStorageDownloadTokens
            );
          }
        });
      });
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      const newItem = await db.collection("items").add({
        BOTM: false,
        acidity: acidity,
        aging: aging,
        bundle: false,
        grapeVariety: grapeVariety,
        offer: false,
        pairing: pairings,
        productBrand: brand,
        productCountry: country,
        productDescription: description,
        productImage: itemImageUrl,
        productName: title,
        productPrice: price,
        productRegion: region,
        productType: category,
        senses: {
          eye: eye,
          mouth: mouth,
          nose: nose,
        },
        servingTemp: servingTemp,
        sugar: sugar,
        vinification: vinification,
        vintage: vintage,
        detailImage: test,
        detailImageDesktop: test2,
      });
      console.log("Item added with ID: ", newItem.id);
      setTitle("");
      setBrand("");
      setDescription("");
      setCategory("");
      setPrice("");
      setRegion("");
      setCountry("");
      setImage("");
      setLoading(false);
      setAcidity("");
      setAging("");
      setGrapeVariety("");

      props.history.push("/pages/posts/all-posts");
    } catch (error) {
      console.error("Error adding item: ", error);
    }
  }

  const handlePairingsChange = (e) => {
    const value = e.target.value;
    if (pairings.includes(value)) {
      setPairings(pairings.filter((pairing) => pairing !== value));
    } else {
      setPairings([...pairings, value]);
    }
  };

  return (
    <div className="cont">
      <button
        className="back-button"
        onClick={() => props.history.push("/pages/posts/all-posts")}
      >
        Back
      </button>
      <form onSubmit={handleSubmit}>
        <h1>Add New Product</h1>
        <label htmlFor="title">Name</label>
        <input
          required
          type="text"
          id="title"
          name="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <label htmlFor="brand">Brand</label>
        <input
          required
          type="text"
          id="brand"
          name="brand"
          value={brand}
          onChange={(e) => setBrand(e.target.value)}
        />
        <label htmlFor="description">Description</label>
        <input
          required
          type="text"
          id="description"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <label htmlFor="category">Category</label>
        <select
          required
          onChange={(e) => setCategory(e.target.value)}
          defaultValue="Category"
        >
          <option value="">Choose Category</option>
          <option value="Red">Red</option>
          <option value="White">White</option>
          <option value="Rose">Rose</option>
          <option value="Sparkling">Sparkling</option>
          <option value="Champagne">Champagne</option>

          <option value="Other">Other</option>
        </select>
        <label htmlFor="price">Price</label>
        <input
          required
          type="number"
          id="price"
          name="price"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <label htmlFor="region">Region</label>
        <input
          required
          type="text"
          id="region"
          name="region"
          onChange={(e) => setRegion(e.target.value)}
          value={region}
        />
        <label htmlFor="country">Country</label>
        <input
          required
          type="text"
          id="country"
          name="country"
          onChange={(e) => setCountry(e.target.value)}
          value={country}
        />
        <label htmlFor="servingTemp">Serving Temp</label>
        <input
          required
          type="text"
          name="servingTemp"
          value={servingTemp}
          onChange={(e) => setServingTemp(e.target.value)}
        />
        {/* <label htmlFor="pairings">Pairings</label>
        <select
          required
          value={pairings}
          onChange={(e) => setPairings(e.target.value)}
        >
          <option value="">Choose Pairing</option>
          <option value="Sea Food">Sea Food</option>
          <option value="Vegies">Vegies</option>
          <option value="Cheese and Ham">Cheese and Ham</option>
          <option value="Poultry">Poultry</option>
          <option value="Red Meat">Red Meat</option>
          <option value="Pasta and Pizza">Pasta and Pizza</option>
        </select> */}
        <label>Pairings</label>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              margin: "8px",
            }}
          >
            <label>
              <input
                type="checkbox"
                value="Sea Food"
                checked={pairings.includes("Sea Food")}
                onChange={handlePairingsChange}
              />
              Sea Food
            </label>
          </div>
          <div
            style={{
              margin: "8px",
            }}
          >
            <label>
              <input
                type="checkbox"
                value="Veggies"
                checked={pairings.includes("Veggies")}
                onChange={handlePairingsChange}
              />
              Veggies
            </label>
          </div>
          <div
            style={{
              margin: "8px",
            }}
          >
            <label>
              <input
                type="checkbox"
                value="Cheese and Ham"
                checked={pairings.includes("Cheese and Ham")}
                onChange={handlePairingsChange}
              />
              Cheese and Ham
            </label>
          </div>
          <div
            style={{
              margin: "8px",
            }}
          >
            <label>
              <input
                type="checkbox"
                value="Poultry"
                checked={pairings.includes("Poultry")}
                onChange={handlePairingsChange}
              />
              Poultry
            </label>
          </div>
          <div
            style={{
              margin: "8px",
            }}
          >
            <label>
              <input
                type="checkbox"
                value="Red Meat"
                checked={pairings.includes("Red Meat")}
                onChange={handlePairingsChange}
              />
              Red Meat
            </label>
          </div>
          <div
            style={{
              margin: "8px",
            }}
          >
            <label>
              <input
                type="checkbox"
                value="Pasta and Pizza"
                checked={pairings.includes("Pasta and Pizza")}
                onChange={handlePairingsChange}
              />
              Pasta and Pizza
            </label>
          </div>
          <div
            style={{
              margin: "8px",
            }}
          >
            <label>
              <input
                type="checkbox"
                value="Desserts"
                checked={pairings.includes("Desserts")}
                onChange={handlePairingsChange}
              />
              Desserts
            </label>
          </div>
        </div>
        <label htmlFor="Acidity">Acidity</label>
        <input
          required
          type="text"
          name="Acidity"
          value={acidity}
          onChange={(e) => setAcidity(e.target.value)}
        />
        <label htmlFor="Aging">Aging</label>
        <input
          required
          type="text"
          name="Aging"
          value={aging}
          onChange={(e) => setAging(e.target.value)}
        />
        <label htmlFor="vintage">Vintage</label>
        <input
          required
          type="text"
          name="vintage"
          value={vintage}
          onChange={(e) => setVintage(e.target.value)}
        />
        <label htmlFor="eye">Senses on Eye</label>
        <input
          required
          type="text"
          name="eye"
          value={eye}
          onChange={(e) => setEye(e.target.value)}
        />
        <label htmlFor="mouth">Senses on Mouth</label>
        <input
          required
          type="text"
          name="mouth"
          value={mouth}
          onChange={(e) => setMouth(e.target.value)}
        />
        <label htmlFor="nose">Senses on Nose</label>
        <input
          required
          type="text"
          name="nose"
          value={nose}
          onChange={(e) => setNose(e.target.value)}
        />
        <label htmlFor="vinification">Vinification</label>
        <input
          required
          type="text"
          name="vinification"
          value={vinification}
          onChange={(e) => setVinification(e.target.value)}
        />
        <label htmlFor="sugar">Sugar</label>
        <input
          required
          type="text"
          name="sugar"
          value={sugar}
          onChange={(e) => setSugar(e.target.value)}
        />
        <label htmlFor="Grape Variety">Grape Variety</label>{" "}
        <input
          required
          type="text"
          name="Grape Variety"
          value={grapeVariety}
          onChange={(e) => setGrapeVariety(e.target.value)}
        />
        <label htmlFor="image-upload">
          Upload Item Image {"(1427 * 3890)"}
        </label>
        <input
          required
          type="file"
          id="image-upload"
          onChange={handlePickItemImage}
          accept="image/*"
        />
        <label htmlFor="image-upload2">
          Upload Details Image {"(1100 * 2166)"}
        </label>
        <input
          required
          type="file"
          id="image-upload2"
          onChange={handlePickDetailImage}
          accept="image/*"
        />
        <label htmlFor="image-upload2">Upload Details Image Desktop</label>
        <input
          required
          type="file"
          id="image-upload2"
          onChange={handlePickDetailImageDesktop}
          accept="image/*"
        />
        {test !== "" && itemImageUrl !== "" && test2 ? (
          <button type="submit">Add Product</button>
        ) : (
          <button type="submit" style={{ opacity: "0.5" }} disabled>
            Add Product
          </button>
        )}
      </form>
    </div>
  );
};

export default AddPostPage;
