import React from "react";
import OftadehLayout from "../../components/OftadehLayout/OftadehLayout";
import {
  Typography,
  Grid,
  Button,
  makeStyles,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { db, firebase } from "../../firebase";

const useStyles = makeStyles((theme) => ({
  my3: {
    margin: "1.3rem 0",
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: ".85rem",
  },
  p1: {
    padding: ".85rem",
  },
}));

const AllProductsPage = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [orders, setOrders] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [editItem, setEditItem] = React.useState("");
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [itemImage, setItemImage] = React.useState("");
  const [detailsImage, setDetailsImage] = React.useState("");
  // const [senseOnEye, setSenseOnEye] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [type, setType] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [isUploading1, setIsUploading1] = React.useState(false);
  const [isUploading2, setIsUploading2] = React.useState(false);
  const [isUploading3, setIsUploading3] = React.useState(false);

  const [detailsImageDesktop, setDetailsImageDesktop] = React.useState("");

  React.useEffect(() => {
    db.collection("Products").onSnapshot((snapshot) => {
      const data = [];

      snapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });

      setOrders(data);
      setLoaded(true);

      console.log(data);
    });
  }, []);

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const types = ["All", "Jars", "Bottles", "Chocolates", "GiftBoxes"];

  const [filterType, setFilterType] = React.useState("All");

  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value);
  };

  const filteredOrders = (searchTerm) => {
    return orders.filter((order) => {
      const productNameMatches = order.Name.toLowerCase().includes(
        searchTerm.toLowerCase()
      );
      const typeMatches = filterType === "All" || order.Type === filterType;

      return productNameMatches && typeMatches;
    });
  };

  const handleImageUpload1 = (e, setImage) => {
    setIsUploading1(true);
    const file = e.target.files[0];
    const storageRef = firebase.storage().ref(`Delicacies/${file.name}`);
    const task = storageRef.put(file);

    task.on(
      "state_changed",
      function progress(snapshot) {
        var percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(percentage);
      },
      function error(err) {
        console.log(err);
      },
      function complete() {
        task.snapshot.ref.getDownloadURL().then((url) => {
          setImage(url);
          setIsUploading1(false);
        });
      }
    );
  };

  const handleImageUpload2 = (e, setImage) => {
    setIsUploading2(true);
    const file = e.target.files[0];
    const storageRef = firebase.storage().ref(`detailDelicacies/${file.name}`);
    const task = storageRef.put(file);

    task.on(
      "state_changed",
      function progress(snapshot) {
        var percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(percentage);
      },
      function error(err) {
        console.log(err);
      },
      function complete() {
        task.snapshot.ref.getDownloadURL().then((url) => {
          setImage(url);
          setIsUploading2(false);
        });
      }
    );
  };
  const handleImageUpload3 = (e, setImage) => {
    setIsUploading3(true);
    const file = e.target.files[0];
    const storageRef = firebase.storage().ref(`detailDelicacies/${file.name}`);
    const task = storageRef.put(file);

    task.on(
      "state_changed",
      function progress(snapshot) {
        var percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(percentage);
      },
      function error(err) {
        console.log(err);
      },
      function complete() {
        task.snapshot.ref.getDownloadURL().then((url) => {
          setImage(url);
          setIsUploading3(false);
        });
      }
    );
  };

  const handleOpen = (order) => {
    setOpen(true);
    setEditItem(order.id);
    setName(order.Name);
    setType(order.Type);
    setDescription(order.Description);
    setPrice(order.Price);
    setItemImage(order.Image);
    setDetailsImage(order.DetailImage);
    setDetailsImageDesktop(order.DetailImageDesktop);
    console.log(order);
  };

  const handleClose = () => {
    setOpen(false);
    setEditItem("");
    setName("");
    setType("");
    setDescription("");
    setPrice("");
    setItemImage("");
    setDetailsImage("");
    setDetailsImageDesktop("");
  };

  const handleSave = () => {
    db.collection("Products")
      .doc(editItem)
      .update({
        Name: name,
        Type: type,
        Description: description,
        Price: price,
        Image: itemImage,
        DetailImage: detailsImage,
        DetailImageDesktop: detailsImageDesktop,
      })
      .then(() => {
        console.log("Document successfully updated!");
        handleClose();
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight}>
          <Typography variant="h5" component="h1">
            Posts
          </Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={() => history.push("/pages/add-product")}
            variant="outlined"
            color="primary"
            size="small"
          >
            Add Product
          </Button>
        </Grid>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchQueryChange}
          placeholder="Search by name or email"
          style={{
            borderRadius: "7px",
            padding: "7px",
            marginLeft: "10px",
          }}
        />
        <Grid
          item
          style={{
            marginLeft: "10px",
          }}
        >
          <FormControl>
            <InputLabel>Type</InputLabel>
            <Select value={filterType} onChange={handleFilterTypeChange}>
              {types.map((type) => (
                <MenuItem value={type} key={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <div className="InnerContainer">
            {loaded &&
              filteredOrders(searchQuery).map((order) => (
                <div
                  key={order.id}
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    margin: "10px",
                    borderRadius: "2px",
                  }}
                >
                  <img
                    src={order.Image}
                    alt=""
                    width={100}
                    height={100}
                    style={{
                      objectFit: "contain",
                    }}
                  />
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {order.Name}
                  </p>
                  <p>{order.Description}</p>
                  <p>{order.Price}$</p>
                  <p>{order.Type}</p>
                  <div>
                    <button
                      style={{
                        borderRadius: "7px",
                        padding: "7px",
                      }}
                      onClick={() => {
                        db.collection("Products").doc(order.id).delete();
                      }}
                    >
                      <Icon>delete</Icon>
                    </button>

                    <button
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "orange",
                        borderRadius: "7px",
                        padding: "7px",
                      }}
                      onClick={() => handleOpen(order)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Item</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <label htmlFor="category">Category</label>
          <select
            required
            onChange={(e) => setType(e.target.value)}
            value={type}
          >
            <option value="">Choose Category</option>
            <option value="Jars">Jars</option>
            <option value="Bottles">Bottles</option>
            <option value="Chocolates">Chocolates</option>
            <option value="GiftBoxes">Gift Boxes</option>
          </select>
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Price"
            fullWidth
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
          <div>
            <label htmlFor="itemImage">Item Image:</label>
            <br />
            {itemImage && (
              <img
                src={itemImage}
                alt="Item"
                width={200}
                height={200}
                style={{ objectFit: "contain" }}
              />
            )}
            <br />
            <input
              type="file"
              id="itemImage"
              onChange={(e) => handleImageUpload1(e, setItemImage)}
            />
          </div>
          <div>
            <label htmlFor="detailsImage">Details Image:</label>
            <br />
            {detailsImage && (
              <img
                src={detailsImage}
                alt="Details"
                width={200}
                height={200}
                style={{ objectFit: "contain" }}
              />
            )}
            <br />
            <input
              type="file"
              id="detailsImage"
              onChange={(e) => handleImageUpload2(e, setDetailsImage)}
            />
          </div>
          <div>
            <label htmlFor="detailsImage">Details Image Desktop:</label>
            <br />
            {detailsImageDesktop && (
              <img
                src={detailsImageDesktop}
                alt="Details"
                width={200}
                height={200}
                style={{ objectFit: "contain" }}
              />
            )}
            <br />
            <input
              type="file"
              id="detailsImageDesktop"
              onChange={(e) => handleImageUpload3(e, setDetailsImageDesktop)}
            />
          </div>
        </DialogContent>
        <Button
          onClick={() =>
            handleSave({
              id: editItem,
              productName: name,
              productType: type,
              productDescription: description,
              productPrice: price,
              productImage: itemImage,
              detailImage: detailsImage,
            })
          }
          color="primary"
          disabled={isUploading1 || isUploading2}
        >
          Save
        </Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </Dialog>
    </OftadehLayout>
  );
};

export default AllProductsPage;
