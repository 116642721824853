// import { MaterialUIComponentsNavigation } from "../pages/documentation/material-ui-components/MaterialUIComponentsNavigation";

const navigationConfig = [
  {
    id: "Main",
    title: "MAIN",
    type: "group",
    children: [
      {
        id: "dashboard",
        title: "Dashboard | Orders",
        type: "item",
        icon: "apps",
        url: "/",
        exact: true,
      },
      {
        id: "posts",
        title: "Products",
        type: "collapse",
        icon: "file_copy",
        children: [
          {
            id: "all products",
            title: "All Posts",
            type: "item",
            url: "/pages/posts",
            exact: true,
          },
          {
            id: "add product",
            title: "Add Post",
            type: "item",
            url: "/pages/posts/add-post",
            exact: true,
          },
        ],
      },
      {
        id: "recipes",
        title: "Recipes",
        type: "collapse",
        children: [
          {
            id: "all recipes",
            title: "All Recipes",
            url: "pages/recipes",
            type: "item",
            exact: true,
          },
          {
            id: "add recipe",
            title: "Add Recipe",
            url: "pages/posts/add-recipe",
            type: "item",
            exact: true,
          },
        ],
      },
      {
        id: "calendar",
        title: "Users",
        type: "item",
        icon: "group-icon",
        url: "/pages/Users",
        exact: true,
      },
      {
        id: "history",
        title: "Order History",
        type: "item",
        url: "/pages/order-history",
        exact: true,
      },
      {
        id: "constants",
        title: "Constants",
        type: "item",
        url: "/pages/constants",
        exact: true,
      },
      {
        id: "slideImages",
        title: "Slide Images",
        type: "item",
        url: "/pages/slide-images",
        exact: true,
      },
      {
        id: "addProduct",
        title: "Add Delicacies",
        type: "item",
        url: "/pages/add-product",
        exact: true,
      },
      {
        id: "allProduct",
        title: "All Delicacies",
        type: "item",
        url: "/pages/all-delicacies",
        exact: true,
      },
    ],
  },
];

export default navigationConfig;
